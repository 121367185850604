@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');
:root {
    --main-color: #3088C8;
    --secondary-color: #81C048;
    --gray-color: #f9f9f9;
    --black-color: #444;
}

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    color: var(--black-color);
    background: #fff;
    overflow-x: hidden !important;
    line-height: 1.6;
    padding-top: 6.5rem;
}

small {
    font-weight: 300;
}
.form-control, .form-control:focus{
    border-radius: 0;
    outline: none;
    box-shadow: none;
    min-height: 42px;
    font-weight: inherit;
}

.bg-gray {
    background: #f8f8f8;
}

.main h1 {
    font-size: 38px;
    color: #555;
}

.main h2 {
    font-size: 30px;
    color: #555;
}

.main h3 {
    font-size: 23px;
    color: #555;
}

.main h4 {
    font-size: 19px;
    color: #555;
}

.content-block-blog p {
    text-align: justify;
}

.main h5 {
    font-size: 17px;
    color: #555;
}

.main h6 {
    font-size: 16px;
    color: #555;
}

.main p {
    text-align: justify;
}

ol, ul {
    margin-left: 1rem;
}

a, a:hover, a:focus, button, button:hover, button:focus, .primary-btn:hover, .primary-btn:focus, .btn-alter:hover, .btn-alter:focus, input, input:focus, img {
    outline: 0;
    text-decoration: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

a {
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    color: var(--secondary-color);
}

a:hover {
    color: var(--main-color);
}

a, button, .primary-btn {
    cursor: pointer;
}

.primary-btn {
    color: #ffffff;
    background: var(--main-color);
    background-image: -webkit-gradient(linear, left top, right top, from(#5c81fa), to(#39a8fe));
    background-image: -webkit-linear-gradient(left, #5c81fa, #39a8fe);
    background-image: -o-linear-gradient(left, #5c81fa, #39a8fe);
    background-image: linear-gradient(to right, #5c81fa, #39a8fe);
    background-repeat: repeat-x;
    display: inline-block;
    padding: 0 1.5rem;
    border: none;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    position: relative;
    z-index: 1;
    white-space: nowrap;
    line-height: 40px;
    font-size: 1.1rem;
    font-weight:300;
}

.primary-btn.btn-full {
    display: block;
}

.primary-btn:hover {
    color: #ffffff;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.primary-btn.btn-white {
    background: #fff;
    color: var(--main-color);
}

.header-wrapper {
    width: 100%;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    z-index: 3000;
}

.header-wrapper.fixed-top {
    top: 0;
    left: auto;
    right: auto;
}
.header-wrapper .navbar {
    padding: 0;
}

.header-wrapper .header-top {
    border-bottom: 1px solid #ececec;
}

.header-wrapper .header-top .contact-numb {
    font-size: 1rem;
    line-height: 1;
    font-weight: 600;
    color: #333333;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
}

.header-wrapper .header-top .contact-numb i {
    font-size: 1.5rem;
    font-weight: 600;
    margin-right: 5px;
    vertical-align: middle;
}

.header-wrapper .header-top .primary-btn {
    line-height: 40px;
    margin-left: 2rem;
}

.header-wrapper .navbar .nav-item .nav-link {
    padding: 0;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    position: relative;
}

.header-wrapper .navbar .nav-item .nav-link::after {
    content: " ";
    position: absolute;
    top: -1px;
    left: auto;
    right: 0;
    width: 0;
    height: 2px;
    display: block;
    background: var(--main-color);
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.header-wrapper .navbar .nav-item .nav-link:hover::after {
    width: 100%;
    left: 0;
}

.header-wrapper .navbar .nav-item.active .nav-link::after {
    width: 100%;
}

.header-wrapper .navbar-toggler {
    border: none;
    outline: none !important;
    cursor: pointer;
}

.header-wrapper .navbar-toggler span.toggler-icon {
    width: 22px;
    height: 3px;
    display: block;
    background: var(--main-color);
    margin: 4px 0;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
}

.navbar .nav-item .nav-link {
    font-size: 1rem;
    line-height: 2.8rem;
    color: #333333;
    font-weight: 400;
    text-transform: capitalize;
}

.navbar .nav-item .nav-link:hover {
    color: var(--main-color);
}

.navbar .nav-item.active .nav-link {
    color: var(--main-color);
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    background-image: none;
    position: relative;
}

.carousel-control-prev-icon:before, .carousel-control-next-icon:before {
    content: "\f104";
    font-family: 'Line Awesome Free';
    font-weight: 900;
    font-size: 1.8rem;
}

.carousel-control-next-icon:before {
    content: "\f105";
}

.carousel .carousel-indicators li {
    width: 0.6rem;
    height: 0.6rem;
    background-color: #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    cursor: pointer;
}

.carousel .carousel-indicators li.active {
    background-color: #fff;
}

.banner {
    background: #eeeeee url('images/banner.jpg') center top no-repeat;
    background-size: cover;
}

.banner {
    min-height: 430px;
    background-color: #eeeeee;
    position: relative;
}

.banner {
    height: calc(100vh - 6.5rem);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.banner p {
    margin-bottom: 1.3rem;
}

.banner .content {
    width: 100%;
    height: 100%;
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(92, 129, 250, 0.7)), to(rgba(57, 168, 254, 0.7)));
    background-image: -webkit-linear-gradient(left, rgba(92, 129, 250, 0.7), rgba(57, 168, 254, 0.7));
    background-image: -o-linear-gradient(left, rgba(92, 129, 250, 0.7), rgba(57, 168, 254, 0.7));
    background-image: linear-gradient(to right, rgba(92, 129, 250, 0.7), rgba(57, 168, 254, 0.7));
    /* background-repeat: repeat-x; */
}

.banner .content h2 {
    color: #ffffff;
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 0;
    margin-bottom: 1.3rem;
}

.banner .content p {
    color: #ffffff;
}

.tab-process .nav-tabs .nav-link, .nav-tabs .nav-link.active {
    padding: 0 1.5rem;
    border: none;
    position: relative;
    background: transparent;
    flex: auto;
    text-align: center;
}

.tab-process .nav-tabs {
    border: none;
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.tab-process .nav-tabs .nav-item:not(:last-child)::before, .tab-process .nav-tabs .nav-item:not(:last-child)::after {
    content: " ";
    position: absolute;
    top: 3rem;
    left: 3rem;
    width: 100%;
    height: 4px;
    background: #e6e6e6;
    z-index: -1;
}

.myIcon {
    width: 6rem;
    height: 6rem;
    background: #ffffff;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.075);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.075);
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    justify-content: center;
    align-items: center;
}

.tab-process .nav-tabs .nav-item.active .myIcon {
    background: var(--main-color);
    color: #fff;
}

.myIcon i {
    font-size: 2.5rem;
}

.tab-process .tab-content {
    padding-top: 4rem;
    line-height: 1.5;
}

.box-with-img {
    height: 100%;
    border: 1px solid #e9e9e9;
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
}

.box-with-img .img-container {
    display: block;
}

.box-with-img .img-container img {
    width: 100%;
}

.box-with-img .text-content {
    padding: 1.2rem 1rem;
}

.box-with-img .text-content h3 {
    margin-bottom: 8px;
}

.box-with-img .text-content p {
    margin-bottom: 0;
}

.box-with-img:hover .text-content h3 a {
    color: var(--main-color);
}

.box-with-img:hover .readmore-btn {
    width: 100%;
    color: var(--main-color);
}

.box-with-img:hover .readmore-btn>div {
    margin-left: 0;
}

.box-with-img:hover .readmore-btn>div i {
    color: var(--main-color);
}

.review-content .review-source {
    position: absolute;
    top: 0;
    right: 0;
    height: 3rem;
}
.review-content .lazy-load-image-loaded{
    display: flex !important;
}

.review-content .media {
    margin-bottom: 1rem;
}

.review-content .media img {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
}

.review-content .media .media-body {
    font-size: 16px;
    color: #888888;
    font-weight: 300;
    margin-top: -5px;
    padding-left: 10px;
}

.review-content {
    padding: 2rem;
    position: relative;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
}

.review-content p {
    margin-bottom: 0;
}

.rating{
    color: #eabe10;
    font-size: 1.2rem;
}

.bg-black {
    background: #222;
    color: #aaa;
}
.payment-ul{
    list-style: none;
    margin: 0;
}
.payment-ul li{
 display: inline-block;
}
.payment-ul li:not(:last-child){
    margin-right: .6rem;
}
.payment-ul li img{
   max-height: 60px;
}

.social-icon {
	margin: 0;
	padding: 0;
	list-style: none;
}
.social-icon i{
	height: 30px;
	width: 30px;
	border-radius: 100%;
	text-align: center;
	line-height: 30px;
    color: #f8f8f8;
    background: var(--main-color);
    background-image: linear-gradient(to right, #5c81fa, #39a8fe);
}
.social-icon i:hover{
    box-shadow: 0 2px 2px rgba(0, 0, 0.2);
}

.social-icon:not(:last-child){
    margin-right: .3rem;
}

.footer .widget-title {
    font-size: 1.1rem;
    color: #f8f8f8;
    font-weight: 300 !important;
}

.footerline {
    border-top: 1px solid #0c0b0b;
    border-bottom: 1px solid #3d3c3c;
}

.my-transparent {
    position: relative;
}

.my-transparent::after {
    content: '';
    background-color: rgba(0, 0, 0, 0.3);
    left: 0;
    right: 0;
    position: absolute;
    top: 0;
    bottom: 0;
}

.my-transparent .container, .my-transparent .container-fluid {
    position: relative !important;
    z-index: 9;
}

.footer-ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: .9rem;
}

.footer-ul li a {
    font-size: .9rem;
    line-height: 32px;
    color: #8c8c8c;
    padding: 0;
}

.footer-ul li a:hover {
    color: #f8f8f8;
}

.feature-block {
    display: block;
}

.feature-block figure {
    display: table-cell;
    position: relative;
    vertical-align: middle;
    margin-bottom: 0;
}

.feature-block figure img, .feature-block figure i {
    margin-right: .5rem;
    font-size: 2rem;
}

.feature-block .content-block {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    padding: 5px;
}

.content-block strong {
    font-size: .9rem;
    letter-spacing: .05rem;
}

.content-block p, .content-block p a{
    font-size: .85rem;
    color: #aaa;
}
.content-block p a:hover{
    color: #f8f8f8;
}
.ip-banner {
    height: 300px;
    background-color: #eeeeee;
    position: relative;
  }
  
  .ip-banner .content {
    width: 100%;
    height: 100%;
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(92, 129, 250, 0.7)), to(rgba(57, 168, 254, 0.7)));
    background-image: -webkit-linear-gradient(left, rgba(92, 129, 250, 0.7), rgba(57, 168, 254, 0.7));
    background-image: -o-linear-gradient(left, rgba(92, 129, 250, 0.7), rgba(57, 168, 254, 0.7));
    background-image: linear-gradient(to right, rgba(92, 129, 250, 0.7), rgba(57, 168, 254, 0.7));
    background-repeat: repeat-x;
  }
  
  .ip-banner .content h1 {
    color: #ffffff;
    margin-bottom: 11px;
  }
  
  .ip-banner .content p {
    color: #ffffff;
    font-weight: 400;
  }


  .breadcrumb {
    background: transparent;
    padding: 0;
    margin: 0;
  }
  
  .breadcrumb .breadcrumb-item {
    font-size: 13px;
    line-height: 54px;
    color: #ffffff;
    font-weight: 400;
    text-transform: uppercase;
    display: inline-block;
    float: none;
  }
  
  .breadcrumb .breadcrumb-item::before {
    display: none;
  }
  
  .breadcrumb .breadcrumb-item a {
    color: #ffffff;
  }
  
  .breadcrumb .breadcrumb-item a i {
    padding: 0 5px;
  }
  
  .breadcrumb .breadcrumb-item a:hover {
    opacity: .7;
  }
  .web-development-banner {
    background: #eeeeee url('images/offshore-default-banner.jpg') center top no-repeat;
    background-size: cover;
 }
 .shadow{
     box-shadow: 0 0 15px rgba(0, 0, 0, 0.05) !important;
 }
 .loader{
    z-index: 99999;
    position: fixed;
    top: 0;
    bottom: 0;
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    background: rgb(0 0 0 / 50%);
    background: #eeeeee url('images/offshore-default-banner.jpg') center top no-repeat;
    background-size: cover;
}
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
.form-check-label{
    cursor: pointer;
}
.c-title{
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 8px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 2rem;
    margin-top: -4px;
}
.box-with-img .title{
    position: absolute;
    bottom: 0;
    padding: .8rem;
    z-index: 1;
    left: 0;
    right: 0;
    text-align: left;
}
.act-overlay {
    background: #000;
    opacity: .5;
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.box-with-img:hover .act-overlay{
 opacity: .15;
}
.box-with-img .title a{
    color: #ddd;
}
.box-with-img:hover .title a{
    color: #fff;;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }
}

.lazy-load-image-background{
    display: block!important;
}

@media screen and (max-width: 991px) {
    body{
        padding-top:5rem;
    }
    .banner{
        height: calc(100vh - 5rem);
    }
    .navbar-collapse>.navbar-nav {
        align-items: initial !important;
        padding-left: 15px;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    }
    .tab-process .nav-tabs .nav-link, .nav-tabs .nav-link.active{
        display: inline-block;
        margin-bottom: 1rem;
    }
    .tab-process .nav-tabs, .nav-tabs{
        display: block;
    }
    .myIcon{
        height: 3rem;
        width: 3rem;
    }
    .myIcon i{
        font-size: 1rem;
    }
    .tab-process .nav-tabs .nav-item:not(:last-child)::before, .tab-process .nav-tabs .nav-item:not(:last-child)::after{
        display: none;
    }
    .ip-banner{
        height: 250px;
    }
    
    .tab-process .tab-content{
        padding-top: 1rem;
    }
    .feature-block figure{
        display: none;
    }
    .feature-block .content-block{
        display: block;
    }
    .review-content .review-source{
        display: none !important;
    }
    
}